@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fillUp {
  0% {
    height: 0;
  }
  80% {
    height: 50px;
  }
  90% {
    height: 60px;
  }
  95% {
    height: 62px;
  }
  100% {
    height: 74px;
  }
}

@keyframes marquee {
  0% { transform: translateX(0%) }
  100% { transform: translateX(-50%) }
}

/* @keyframes fillUp {
  from {
      transform: translate(0, '100px');
  }
  to {
      transform: translate(0, '-100px');
  }
} */
